<template>
<div>
  <div class="d-flex justify-content-end pb-1">
  <b-button
      variant="primary"
      @click="$router.push('importeren/groepen')"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      
    >Groepen importeren?
    </b-button>
  </div>
  <b-card>
    <label for="wildcard">Importeer .xslx bestand</label>
    <b-form-file
      class="mb-2"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      v-model="file"
      @input="handleDrop"
    />
    <b-alert v-if="duplicates.length > 0" class="mb-2" show variant="primary"
      ><div class="alert-body">
        Er {{ duplicates.length == 1 ? "is" : "zijn" }}
        {{ duplicates.length == 0 ? "geen" : duplicates.length }} gebruiker{{
          duplicates.length == 1 ? "" : "s"
        }}
        die reeds in je database
        {{ duplicates.length == 1 ? "staat" : "staan" }}. Selecteer de
        gebruiker(s) die je wil overschrijven.
      </div></b-alert
    >
    <b-alert
      v-else-if="importObjects.length > 0"
      variant="success"
      class="mb-2"
      show
      ><div class="alert-body">
        Data ziet er goed uit! Klaar om te importeren.
      </div></b-alert
    >
    <b-alert
      v-if="
        importObjects.length - (duplicates.length - selectedDups.length) >
          500 || data.length > 500
      "
      show
      variant="danger"
      class="mb-2"
      ><div class="alert-body">
        We ondersteunen max. 500 lijnen per import.
      </div></b-alert
    >
    <b-button
      v-if="duplicates.length > 0"
      variant="primary"
      @click="importUser"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="
        loading ||
        (importObjects.length - (duplicates.length - selectedDups.length) ==
          0 &&
          importObjects.length - (duplicates.length - selectedDups.length) > 0)
      "
    >
      {{
        importObjects.length - (duplicates.length - selectedDups.length)
      }}
      gebruikers importeren
    </b-button>

    <b-table
      class="my-2"
      v-if="duplicates.length > 0"
      striped
      hover
      :items="duplicates"
      :fields="tableColumns"
    >
      <!-- A custom formatted header cell for field 'selected' -->
      <template #head(selected)>
        <b-form-checkbox
          @change="selectAll"
          :checked="selectedDups.length === duplicates.length"
          :indeterminate="
            selectedDups.length < duplicates.length && selectedDups.length > 0
          "
        ></b-form-checkbox>
      </template>
      <template #cell(selected)="data">
        <b-form-checkbox
          name="selected-items"
          v-model="selectedDups"
          :value="data.item.uoid"
        ></b-form-checkbox>
      </template>
    </b-table>

    <b-button
      variant="primary"
      @click="importUser"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="
        loading ||
        (importObjects.length - (duplicates.length - selectedDups.length) ==
          0 &&
          importObjects.length - (duplicates.length - selectedDups.length) > 0)
      "
    >
      {{
        importObjects.length - (duplicates.length - selectedDups.length)
      }}
      gebruikers importeren
    </b-button>

    <b-modal
      title="Velden koppelen"
      hide-footer
      v-model="matchFieldsVisible"
      no-close-on-backdrop
    >
      <b-form @submit.prevent="setFields">
        <b-row>
          <!-- Field: Firstname -->
          <b-col cols="12" md="12">
            <b-form-group label="ID" label-for="firstname">
              <v-select
                v-model="matchData.uoid"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="headers"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Firstname -->
          <b-col cols="12" md="12">
            <b-form-group label="Voornaam" label-for="firstname">
              <v-select
                v-model="matchData.firstname"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="headers"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Name -->
          <b-col cols="12" md="12">
            <b-form-group label="Naam" label-for="name">
              <v-select
                v-model="matchData.lastname"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="headers"
              />
            </b-form-group>
          </b-col>

          <!-- Field: DisplayName -->
          <b-col cols="12" md="12">
            <b-form-group label="Weergavenaam" label-for="displayName">
              <v-select
                v-model="matchData.displayName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="headers"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Birth Date -->
          <b-col cols="12" md="6">
            <b-form-group label="Geboortedatum" label-for="birth-date">
              <v-select
                v-model="matchData.birthday"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="headers"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Gender -->
          <b-col cols="12" md="12">
            <b-form-group
              label="Geslacht"
              label-for="gender"
              label-class="mb-1"
            >
              <v-select
                v-model="matchData.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="headers"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Type -->
          <b-col cols="12" md="6">
            <b-form-group label="Type" label-for="type" label-class="mb-1">
              <v-select
                v-model="matchData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="headers"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <h6 class="section-label mb-1 mt-2">
              Persoonlijke Contactgegevens
            </h6>
          </b-col>

          <!-- phone -->
          <b-col cols="12" class="mb-2">
            <b-row :key="k" v-for="(p, k) in matchData.phones">
              <b-col cols="10">
                <b-form-group
                  label-for="phone"
                  :label="'Telefoonnummer ' + (k + 1)"
                >
                  <v-select
                    v-model="matchData.phones[k]"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :disabled="loading"
                    :closeOnSelect="true"
                    :options="headers"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removePhone(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addPhone"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>Telefoonnummer toevoegen</span>
            </b-button>
          </b-col>
          <!-- phone -->

          <!-- email -->
          <b-col cols="12" class="mb-2">
            <b-row :key="k" v-for="(p, k) in matchData.emails">
              <b-col cols="10">
                <b-form-group label-for="email" :label="'E-mail ' + (k + 1)">
                  <v-select
                    v-model="matchData.emails[k]"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :disabled="loading"
                    :closeOnSelect="true"
                    :options="headers"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeEmail(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addEmail"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>E-mail toevoegen</span>
            </b-button>
          </b-col>
          <!-- email -->

          <!-- email -->
          <b-col cols="12" class="mb-2">
            <b-row :key="k" v-for="(p, k) in matchData.addresses">
              <b-col sm="12">
                <h6 class="form-label mb-1 mt-2">
                  {{ k == 0 ? "Hoofdadres" : "Adres " + (k + 1) }}
                </h6>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Straat">
                  <v-select
                    v-model="matchData.addresses[k].street"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :disabled="loading"
                    :closeOnSelect="true"
                    :options="headers"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Nr.">
                  <v-select
                    v-model="matchData.addresses[k].housenumber"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :disabled="loading"
                    :closeOnSelect="true"
                    :options="headers"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Bus">
                  <v-select
                    v-model="matchData.addresses[k].addition"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :disabled="loading"
                    :closeOnSelect="true"
                    :options="headers"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeAddress(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Postcode">
                  <v-select
                    v-model="matchData.addresses[k].postal"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :disabled="loading"
                    :closeOnSelect="true"
                    :options="headers"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Plaats">
                  <v-select
                    v-model="matchData.addresses[k].city"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :disabled="loading"
                    :closeOnSelect="true"
                    :options="headers"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addAddress"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>Adres toevoegen</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :disabled="loading"
        >
          Opslaan
        </b-button>
      </b-form>
    </b-modal>
  </b-card>
</div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BSpinner,
  BAlert,
  BFormFile,
  BTable,
  BMedia,
  BMediaAside,
  BMediaBody,
  BOverlay,
} from "bootstrap-vue";

import vSelect from "vue-select";

import Ripple from "vue-ripple-directive";

import XLSX from "xlsx";

import { db } from "@/firebase/firebaseConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import mainMixin from '@/mixins/mainMixin';

export default {
  mixins: [mainMixin],
  directives: { Ripple },
  components: {
    BFormCheckbox,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BSpinner,
    BAlert,
    BFormFile,
    BTable,
    BMedia,
    BMediaAside,
    BMediaBody,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      file: null,
      headers: [],
      data: [],
      importObjects: [],
      loading: false,
      matchFieldsVisible: false,
      selectedDups: [],
      tableColumns: [
        { key: "selected", label: "" },
        { key: "uoid", label: "ID", sortable: true },
        { key: "displayName", label: "Weergavenaam", sortable: true },
        { key: "firstname", label: "Voornaam", sortable: true },
        { key: "lastname", label: "Naam", sortable: false },
        { key: "email", label: "E-mail", sortable: false },
        { key: "phone", label: "Telefoon", sortable: false },
        { key: "birthday", label: "Geboortedatum", sortable: false },
      ],
      matchData: {
        uoid: null,
        groupIds: [],
        groups: {},
        organisationIds: [this.$store.state.organisation.id],
        organisations: {},
        type: null,
        displayName: null,
        firstname: null,
        lastname: null,
        birthday: null,
        gender: null,
        phone: null,
        phones: [],
        email: null,
        emails: [],
        address: null,
        addresses: [],
      },
    };
  },
  created() {
    this.fetchUsers();
  },
  computed: {
    duplicates() {
      return (
        this.importObjects
          .filter((item) => item.duplicate == true)
          .map((item) => {
            return {
              uoid: item.uoid,
              firstname: item.firstname,
              lastname: item.lastname,
              email: item.email,
              phone: item.phone,
              birthday: item.birthday,
            };
          }) ?? []
      );
    },
  },
  methods: {
    selectAll() {
      if (this.selectedDups.length === this.duplicates.length) {
        this.selectedDups = [];
      } else {
        this.selectedDups = this.duplicates.map((d) => d.uoid);
      }
    },
    importUser() {
      this.loading = true;
      var batch = db.batch();
      this.importObjects.forEach((obj) => {
        console.log(obj, obj.uoid);
        if (!obj.duplicate || this.selectedDups.includes(obj.uoid)) {
          if(obj.duplicate){
            delete obj.duplicate;
          }
          
          batch.set(
            db
              .collection("organisations")
              .doc(this.$store.state.organisation.id)
              .collection("users")
              .doc(obj.uoid),
            obj,
            { merge: true }
          );
        }
      });
      return batch
        .commit()
        .then(() => {
          return  this.$store
          .dispatch(
            "userManagement/fetchUsers",
            this.$store.state.organisation.id
          )
        })
          .then(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alle rijen geimporteerd!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          return;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Er ging iets fout!",
              text: error.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    handleDrop(e) {
      // only use files[0]
      this.readerData(e);
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C = undefined;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = `UNKNOWN ${C}`; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },

    readerData(rawFile) {
      return new Promise((resolve) => {
        try{
          const reader = new FileReader();
        reader.onload = (e) => {
          var res = [];
          var sheetNames = [];
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          workbook.SheetNames.forEach((sheet) => {
            const worksheet = workbook.Sheets[sheet];
            const header = this.getHeaderRow(worksheet);
            const results = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            const meta = { sheetName: sheet };
            res.push({ header, results, meta });
            sheetNames.push(sheet);
          });
          this.headers = res[0].header;
          this.data = res[0].results;
          if(this.data.length <= 500){
              this.matchFieldsVisible = true;
          }
          resolve();
        };
        reader.onerror = function(ex) {
          console.log(ex);
        };

        reader.readAsArrayBuffer(rawFile);
        } catch (error){
          console.log(error);
          reject(error);
        }
        
      });
     
    },
    fetchUsers() {
      if (
        !(
          this.$store.state.userManagement.users &&
          this.$store.state.userManagement.users.length > 1
        )
      ) {
        this.$store.dispatch(
          "userManagement/fetchUsers",
          this.$store.state.organisation.id
        );
      }
    },
    setFields() {
      this.buildObjects();
      this.matchFieldsVisible = false;
    },
    buildObjects() {
      this.data.forEach((d, i) => {
        var obj = {
          uoid: d[this.matchData.uoid] ? d[this.matchData.uoid].toString() : this.generateUUID(),
          groupIds: [],
          groups: {},
          organisationIds: [this.$store.state.organisation.id],
          organisations: {},
          type: d[this.matchData.type] ?? "member",
          displayName:
            d[this.matchData.displayName] ??
            d[this.matchData.firstname] + " " + d[this.matchData.lastname],
          firstname: d[this.matchData.firstname] ?? "",
          lastname: d[this.matchData.lastname] ?? "",
          birthday: d[this.matchData.birthday] ?? "",
          gender: this.processGender(d[this.matchData.gender] ?? ""),
          phone: d[this.matchData.phones[0]] ?? "",
          phones: this.matchData.phones.map((v) => d[v]) ?? [],
          email: d[this.matchData.emails[0]] ?? "",
          emails: this.matchData.emails.map((v) => d[v]) ?? [],
          address: d[this.matchData.addresses[0]] ?? "",
          addresses: this.matchData.addresses.map((v) => d[v]) ?? [],
        };
        obj.organisations[this.$store.state.organisation.id]= {
          type: obj.type,
          uoid: obj.uoid,
          shortName: this.$store.state.organisation.shortName

        }
        if (this.checkForDuplicates(obj) > -1) {
          obj.duplicate = true;
          obj.uoid = this.$store.state.userManagement.users[
            this.checkForDuplicates(obj)
          ].uoid;
        } else {
          obj.duplicate = false;
        }
        this.importObjects.push(obj);
      });
      this.selectAll();
    },
    checkForDuplicates(obj) {
      return this.$store.state.userManagement.users.findIndex(
        (v) =>
          (obj.uoid && v.uoid == obj.uoid) ||
          (v.firstname == obj.firstname && v.lastname == obj.lastname)
      );
    },
    addPhone() {
      this.matchData.phones.push("");
    },
    removePhone(index) {
      this.matchData.phones.splice(index, 1);
    },
    addEmail() {
      this.matchData.emails.push("");
    },
    removeEmail(index) {
      this.matchData.emails.splice(index, 1);
    },
    addAddress() {
      this.matchData.addresses.push({});
    },
    removeAddress(index) {
      this.matchData.addresses.splice(index, 1);
    },
    processGender(g) {
      g = g.toString().toLowerCase();
      if (
        g == "male" ||
        g == "m" ||
        g == "man" ||
        g == "jongen" ||
        g == "boy" ||
        g == "j"
      ) {
        return "M";
      } else if (
        g == "female" ||
        g == "f" ||
        g == "vrouw" ||
        g == "meisje" ||
        g == "girl" ||
        g == "g"
      ) {
        return "F";
      } else if (g != "" && g != null) {
        return "X";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>